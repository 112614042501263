import { createContext, useContext, useState } from "react";
import { pages } from "../links";

function CurrentPageStore() {
  const currentPageInitialState = {
    currentPage: pages.MAIN,
    previousPage: undefined,
  };

  const [currentPage, setCurrentPage] = useState(currentPageInitialState);

  return {
    updateCurrentPage(destinationPage) {
      setCurrentPage((prev) => ({ currentPage: destinationPage, previousPage: prev.currentPage }));
    },

    goBack() {
      setCurrentPage((prev) => ({
        currentPage: pages.MAIN,
        previousPage: prev.currentPage,
      }));
    },

    currentPage: currentPage.currentPage,
  };
}

const CurrentPageContext = createContext(null);

export const useCurrentPage = () => useContext(CurrentPageContext);

export const CurrentPageProvider = ({ children }) => {
  const currentPageStore = CurrentPageStore();

  return (
    <CurrentPageContext.Provider value={currentPageStore}>{children}</CurrentPageContext.Provider>
  );
};
